var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-info-audit-form-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "项目住址",
                      text: _vm.detail.address,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "用户信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "姓名",
                      text: _vm.detail.managerName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "性别",
                      text: _vm.sexMap[_vm.detail.managerSex],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系电话",
                      text: _vm.detail.managerMobileNum,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "申请人信息" } },
                [
                  _c("col2-item", {
                    attrs: { span: 24, label: "姓名", text: _vm.detail.inuser },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "性别",
                      text: _vm.sexMap[_vm.detail.role],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系电话",
                      text: _vm.detail.mobileNum,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "身份",
                      text: _vm.userTypeMap[_vm.detail.userType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "申请时间",
                      text: _vm.detail.applyTime,
                    },
                  }),
                  _vm.detail.userType - 0 === 3
                    ? _c(
                        "div",
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "租期开始时间",
                              text: _vm.detail.rentStartDate,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "租期结束时间",
                              text: _vm.detail.rentEndDate,
                            },
                          }),
                          _vm.rentImgUrls.length
                            ? _c(
                                "col2-item",
                                { attrs: { span: 24, label: "租赁合同" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-preview", {
                                        attrs: { imgs: _vm.rentImgUrls },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "申请人信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "修正房号" } },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询房屋" },
                            model: {
                              value: _vm.form.roomId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roomId", $$v)
                              },
                              expression: "form.roomId",
                            },
                          },
                          "v-select2",
                          _vm.roomIdParams,
                          false
                        )
                      ),
                      _c("i", { staticClass: "tips" }, [
                        _vm._v(
                          "注：如果确认申请人的房号信息，可直接修正房号。"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审核状态",
                        prop: "isExamine",
                        rules: [
                          {
                            required: true,
                            message: "请选择审核状态",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.auditStatusOpts },
                        model: {
                          value: _vm.form.isExamine,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isExamine", $$v)
                          },
                          expression: "form.isExamine",
                        },
                      }),
                      _c("i", { staticClass: "tips" }, [
                        _vm._v("注：请确认核对用户信息，再进行操作。"),
                      ]),
                    ],
                    1
                  ),
                  _vm.refuseReasonVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拒绝原因",
                            rules: [
                              {
                                required: true,
                                message: "请填写拒绝原因",
                                trigger: "blur",
                              },
                            ],
                            prop: "rejectReason",
                          },
                        },
                        [
                          _c("v-textarea", {
                            model: {
                              value: _vm.form.rejectReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rejectReason", $$v)
                              },
                              expression: "form.rejectReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }