// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverUserAction!examineList.action`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}asset/exportOwnerExamineListNotPass`
// 获取用户信息审核详情
const getDetailURL = `${API_CONFIG.baseURL}serverUserAction!queryExamine.action`
// 审批通过操作
const auditURL = `${API_CONFIG.butlerBaseURL}ownerInfo/ownerExamine`

const searchRoomURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 导出用户信息待审核
const exportUnAuditListURL = `${API_CONFIG.butlerBaseURL}asset/exportOwnerExamineList`

export {
  getListURL,
  exportListURL,
  auditURL,
  getDetailURL,
  searchRoomURL,
  exportUnAuditListURL
}
