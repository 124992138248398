<template>
  <div class="user-info-audit-form-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <col2-item :span="24" label="项目住址" :text="detail.address" />
        </col2-block>
        <col2-block title="用户信息">
          <col2-item :span="24" label="姓名" :text="detail.managerName" />
          <col2-item :span="24" label="性别" :text="sexMap[detail.managerSex]" />
          <col2-item :span="24" label="联系电话" :text="detail.managerMobileNum" />
        </col2-block>
        <col2-block title="申请人信息">
          <col2-item :span="24" label="姓名" :text="detail.inuser" />
          <col2-item :span="24" label="性别" :text="sexMap[detail.role]" />
          <col2-item :span="24" label="联系电话" :text="detail.mobileNum" />
          <col2-item :span="24" label="身份" :text="userTypeMap[detail.userType]" />
          <col2-item :span="24" label="申请时间" :text="detail.applyTime" />
          <div v-if="(detail.userType - 0) === 3">
            <col2-item :span="24" label="租期开始时间" :text="detail.rentStartDate" />
            <col2-item :span="24" label="租期结束时间" :text="detail.rentEndDate" />
            <col2-item :span="24" label="租赁合同" v-if="rentImgUrls.length">
              <div>
                <v-preview :imgs="rentImgUrls"></v-preview>
              </div>
            </col2-item>
          </div>
        </col2-block>
        <col2-block title="申请人信息">
          <el-form-item label="修正房号">
            <v-select2 placeholder="查询房屋" v-model="form.roomId" v-bind="roomIdParams"></v-select2>
            <i class="tips">注：如果确认申请人的房号信息，可直接修正房号。</i>
          </el-form-item>
          <el-form-item label="审核状态" prop="isExamine" :rules="[{ required: true, message: '请选择审核状态', trigger: 'blur' }]">
            <v-select v-model="form.isExamine" :options="auditStatusOpts"></v-select>
            <i class="tips">注：请确认核对用户信息，再进行操作。</i>
          </el-form-item>
          <el-form-item v-if="refuseReasonVisible" label="拒绝原因" :rules="[{ required: true, message: '请填写拒绝原因', trigger: 'blur' }]" prop="rejectReason">
            <v-textarea v-model="form.rejectReason"></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { sexMap, auditStatusOpts, userTypeMap } from './map'
import { getDetailURL, searchRoomURL, auditURL } from './api'
import { Col2Block, Col2Detail, Col2Item } from '@/components/bussiness'
import { vPreview } from '@/components/control'

export default {
  name: 'userInfoAuditForm',
  components: {
    Col2Block,
    Col2Detail,
    Col2Item,
    vPreview
  },
  data () {
    return {
      sexMap: { 0: '未知', ...sexMap },
      orgInfoList: [],
      queryUrl: getDetailURL,
      submitUrl: auditURL,
      form: {
        isExamine: 1,
        rejectReason: '',
        roomId: ''
      },
      detail: {},
      roomId: '',
      roomIdParams: {
        searchUrl: searchRoomURL,
        request: {
          text: 'roomNum'
        }
      },
      auditStatusOpts,
      userTypeMap
    }
  },
  computed: {
    refuseReasonVisible () {
      return this.form.isExamine === 2
    },
    rentImgUrls () {
      let arr = []
      let imgUrls = (this.detail && this.detail.imgUrls && this.detail.imgUrls.length) ? this.detail.imgUrls : []
      imgUrls.forEach(item => {
        arr.push({
          src:  item
        })
      });
      return arr
    }
  },
  mounted () {
    this.$refs.form.getData({ id: this.$route.query.id })
    this.$setBreadcrumb('详情')
  },
  methods: {
    update (data) {
      this.detail = { ...this.form, ...data }
      this.form.rejectReason = data.rejectReason || ''
      this.form.assetId = data.id
      this.form.dataObject = `${data.inuser}-${data.mobileNum}`
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item {
    text-align: left;
  }
</style>
<style lang="scss">
.user-info-audit-form-container {
  .select2Parent {
    width: 250px !important;
  }
  .el-form-item__content {
    display: flex;
  }
  .tips {
    margin-left: 10px;
  }
}
</style>
